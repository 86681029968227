import {
  MENU_SERVICE_ITEM_KEY,
  menuAdvisoryItems,
  menuCombanyItems,
  menuIndustryItems,
  menuServiceItems,
} from '@common/menuData';
import { IconFacebook, IconLinkedin, IconTwitter, IconYoutube } from '@public/icons';
import { Col, Grid, Row } from 'antd';
import { TFunction, useTranslation } from 'next-i18next';
import Image from 'next/image';

import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { SectionTitle } from './SectionTitle';
import { getSiteUrl } from 'utils';

const socials = [
  {
    name: 'linkedin',
    icon: IconLinkedin,
    link: 'https://www.linkedin.com/company/official-ekotek/',
  },
  {
    name: 'Youtube',
    icon: IconYoutube,
    link: 'https://youtube.com/@official-ekotek',
  },
  {
    name: 'Facebook',
    icon: IconFacebook,
    link: 'https://www.facebook.com/OfficialEkotek',
  },
  {
    name: 'Twitter',
    icon: IconTwitter,
    link: 'https://x.com/official_ekotek',
  },
];

const office = (t: TFunction) => [
  {
    location: t('home.txtVietnam'),
    address: t('home.addressVietnam'),
    office: t('home.txtHanoiOffice'),
    phone: '+84-24-6658-3530',
  },
  {
    location: t('home.txtVietnam'),
    address: t('home.addressDanang'),
    office: t('home.txtDanangOffice'),
    phone: '+84-24-6658-3530',
  },
  {
    location: t('home.txtJapan'),
    address: t('home.addressJapan'),
    office: t('home.txtTokyoOffice'),
    phone: '+81-3-4400-4880',
  },
  {
    location: t('home.txtUSA'),
    address: t('home.addressUSA'),
    office: t('home.txtUSAOffice'),
    phone: '',
  },
];

const contactInformation = [
  {
    href: 'mailto:contact@ekotek.vn',
    textContact: 'common.txtBusiness',
    textDescription: 'contact@ekotek.vn',
  },
  {
    href: 'mailto:marketing@ekotek.vn',
    textContact: 'common.txtPartnership',
    textDescription: 'marketing@ekotek.vn',
  },
  // {
  //   href: 'tel:+84-24-6658-3530',
  //   textContact: 'common.txtPhone',
  //   textDescription: '+84-24-6658-3530',
  // },
];

const INITIAL_MOBILE_MENU_DROPDOWN_VISIBLE = {
  outsourceService: false,
  staffAugmentation: false,
  emergingTech: false,
  products: false,
  industry: false,
  company: false,

  advisory: false,
  hire_developer: false,
  blockchain: false,
  development_services: false,
  emerging_technologies: false,
};

const FooterComponentV2 = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { useBreakpoint } = Grid;

  const { sm } = useBreakpoint();
  const isMobile = !sm;
  const [mobileMenuDropDownVisible, setMobileMenuDropDownVisible] = useState(INITIAL_MOBILE_MENU_DROPDOWN_VISIBLE);

  useEffect(() => {
    setMobileMenuDropDownVisible(INITIAL_MOBILE_MENU_DROPDOWN_VISIBLE);
  }, [router]);

  const setMobileDropDownVisible = (menuName: string) => () => {
    setMobileMenuDropDownVisible(() => ({
      ...INITIAL_MOBILE_MENU_DROPDOWN_VISIBLE,
      company: menuName !== 'company',
      [menuName]: !mobileMenuDropDownVisible[menuName],
    }));
  };

  return (
    <div className="footer">
      <div className="container">
        <Row
          gutter={[
            {
              md: 20,
              xxl: 20,
            },
            10,
          ]}
        >
          <Col xs={24} md={8} xl={8} className="column-logo">
            <div className="footer__logo">
              <Image
                src="/images/logo-light.svg"
                alt="Ekotek Logo"
                layout="fill"
                className="image"
                quality={100}
                objectFit="contain"
              />
            </div>
            <ul className="footer__contact">
              {contactInformation.map((contact, index) => (
                <li key={index}>
                  <a href={contact.href} className="email information" aria-label="email">
                    <span className="text-contact">{t(contact.textContact)}</span>
                    <span>:</span>
                    <span className="information__description">{contact.textDescription}</span>
                  </a>
                </li>
              ))}

              <div className="footer__bottom-center lap">
                <ul className="list socials">
                  {socials.map(({ link, icon: Icon, name }, index) => (
                    <li className="item socials__item" key={index}>
                      <a href={link} aria-label={name} target="_blank" rel="noreferrer">
                        <Icon />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <li>
                <div className="section-address">
                  {office(t).map(({ address, location, office, phone }, index) => (
                    <div className="section-contain" key={index}>
                      <div className="section-contain__name">
                        {location}
                        <Image
                          src="/icons/dots.png"
                          alt="icon dot"
                          width={5}
                          height={5}
                          objectFit="contain"
                          quality={100}
                        />
                        {office}
                      </div>
                      <div className="section-contain__content" dangerouslySetInnerHTML={{ __html: address }} />
                      <a className="section-contain__content" style={{ marginTop: '8px' }} href={`tel:${phone}`}>
                        {phone}
                      </a>
                    </div>
                  ))}
                </div>
              </li>
            </ul>
          </Col>

          <Col xs={24} md={16} xl={16}>
            <Row wrap justify="space-between">
              {/* Development Services - Advisory */}
              <Col className="section-col">
                <div className="section">
                  <SectionTitle
                    title={
                      menuServiceItems(t, true).find((value) => value.id === MENU_SERVICE_ITEM_KEY.DEVELOPMENT_SERVICES)
                        .title
                    }
                    onClick={setMobileDropDownVisible('development_services')}
                    dropdownVisible={mobileMenuDropDownVisible.development_services}
                  />
                  {(!isMobile || mobileMenuDropDownVisible.development_services) &&
                    menuServiceItems(t, true)
                      .find((value) => value.id === MENU_SERVICE_ITEM_KEY.DEVELOPMENT_SERVICES)
                      .childServices.map(({ name, link, isExternal, ...props }, index) => (
                        <div className="section-contain" key={index}>
                          <Link href={link}>
                            <a
                              className={classNames('section-contain__subtitle', { 'disable-pointer-event': !link })}
                              target={isExternal ? '_blank' : '_self'}
                              {...props}
                            >
                              {name}
                            </a>
                          </Link>
                        </div>
                      ))}
                </div>
                <div className="section">
                  <SectionTitle
                    title={
                      menuServiceItems(t, true).find(
                        (value) => value.id === MENU_SERVICE_ITEM_KEY.EMERGING_TECHNOLOGIES,
                      ).title
                    }
                    onClick={setMobileDropDownVisible('emerging_technologies')}
                    dropdownVisible={mobileMenuDropDownVisible.emerging_technologies}
                  />
                  {(!isMobile || mobileMenuDropDownVisible.emerging_technologies) &&
                    menuServiceItems(t, true)
                      .find((value) => value.id === MENU_SERVICE_ITEM_KEY.EMERGING_TECHNOLOGIES)
                      .childServices.map(({ name, link, isExternal, ...props }, index) => (
                        <div className="section-contain" key={index}>
                          <Link href={link || '/'}>
                            <a
                              className={classNames('section-contain__subtitle', { 'disable-pointer-event': !link })}
                              target={isExternal ? '_blank' : '_self'}
                              {...props}
                            >
                              {name}
                            </a>
                          </Link>
                        </div>
                      ))}
                </div>

                <div className="section">
                  <SectionTitle
                    title={
                      menuServiceItems(t).find((value) => value.id === MENU_SERVICE_ITEM_KEY.HIRE_DEVELOPERS).title
                    }
                    onClick={setMobileDropDownVisible('hire_developer')}
                    dropdownVisible={mobileMenuDropDownVisible.hire_developer}
                  />
                  {(!isMobile || mobileMenuDropDownVisible.hire_developer) &&
                    menuServiceItems(t)
                      .find((value) => value.id === MENU_SERVICE_ITEM_KEY.HIRE_DEVELOPERS)
                      .childServices.map(({ name, link, isExternal, ...props }, index) => (
                        <div className="section-contain" key={index}>
                          <Link href={link || '/'}>
                            <a
                              className={classNames('section-contain__subtitle', { 'disable-pointer-event': !link })}
                              target={isExternal ? '_blank' : '_self'}
                              {...props}
                            >
                              {name}
                            </a>
                          </Link>
                        </div>
                      ))}
                </div>
                <div className="section">
                  <SectionTitle
                    title={t('service.txtAdvisory')}
                    onClick={setMobileDropDownVisible('hire_developer')}
                    dropdownVisible={mobileMenuDropDownVisible.hire_developer}
                  />
                  {(!isMobile || mobileMenuDropDownVisible.hire_developer) &&
                    menuAdvisoryItems(t).map(({ id, title, path, isExternal, ...props }) => (
                      <div className="section-contain" key={id}>
                        <Link href={path || '/'}>
                          <a
                            className={classNames('section-contain__subtitle', { 'disable-pointer-event': !path })}
                            target={isExternal ? '_blank' : '_self'}
                            {...props}
                          >
                            {title}
                          </a>
                        </Link>
                      </div>
                    ))}
                </div>
              </Col>

              {/* Emerging Technologies - Hire Developers*/}
              <Col className="section-col">
                <div className="section">
                  <SectionTitle
                    title={menuServiceItems(t).find((value) => value.id === MENU_SERVICE_ITEM_KEY.ADVISORY).title}
                    onClick={setMobileDropDownVisible('advisory')}
                    dropdownVisible={mobileMenuDropDownVisible.advisory}
                  />
                  {(!isMobile || mobileMenuDropDownVisible.advisory) &&
                    menuServiceItems(t)
                      .find((value) => value.id === MENU_SERVICE_ITEM_KEY.ADVISORY)
                      .childServices.map(({ name, link, isExternal, ...props }, index) => (
                        <div className="section-contain" key={index}>
                          {!props?.items ? (
                            <Link href={link || '/'}>
                              <a
                                className={classNames('section-contain__subtitle', { 'disable-pointer-event': !link })}
                                target={isExternal ? '_blank' : '_self'}
                                {...props}
                              >
                                {name}
                              </a>
                            </Link>
                          ) : (
                            <>
                              <div className="submenu-label">{name}</div>
                              {props?.items &&
                                props?.items?.map((item, index) => (
                                  <div className="section-contain" key={index}>
                                    <Link href={item.link || '/'}>
                                      <a
                                        className={classNames('section-contain__subtitle', {
                                          'disable-pointer-event': !item.link,
                                        })}
                                        target={item.isExternal ? '_blank' : '_self'}
                                        {...item}
                                      >
                                        {item.name}
                                      </a>
                                    </Link>
                                  </div>
                                ))}
                            </>
                          )}
                        </div>
                      ))}
                </div>
              </Col>

              {/* Blockchain */}
              <Col className="section-col">
                <div className="section">
                  <SectionTitle
                    title={t('combany.txtCombany')}
                    onClick={setMobileDropDownVisible('company')}
                    dropdownVisible={!mobileMenuDropDownVisible.company}
                  />
                  {(!isMobile || !mobileMenuDropDownVisible.company) &&
                    menuCombanyItems(t).map(({ title, path, isExternal, ...props }, index) => (
                      <div className="section-contain" key={index}>
                        <Link href={path || '/'}>
                          <a
                            className={classNames('section-contain__subtitle', { 'disable-pointer-event': !path })}
                            target={isExternal ? '_blank' : '_self'}
                            {...props}
                          >
                            {title}
                          </a>
                        </Link>
                      </div>
                    ))}
                </div>
              </Col>

              {/* Company - Industry */}
              <Col className="section-col portfolio-section">
                <div className="section">
                  <SectionTitle
                    title={t('industry.industry')}
                    onClick={setMobileDropDownVisible('industry')}
                    dropdownVisible={mobileMenuDropDownVisible.industry}
                  />
                  {(!isMobile || mobileMenuDropDownVisible.industry) &&
                    menuIndustryItems(t).map(({ title, path, isExternal, ...props }, index) => (
                      <div className="section-contain" key={index}>
                        <Link href={path || '/'}>
                          <a
                            className={classNames('section-contain__subtitle', { 'disable-pointer-event': !path })}
                            target={isExternal ? '_blank' : '_self'}
                            {...props}
                          >
                            {title}
                          </a>
                        </Link>
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="footer__bottom">
          <Row justify="space-between">
            <Col xs={{ order: 3 }} sm={{ order: 1 }}>
              <div className="footer__bottom-right" dangerouslySetInnerHTML={{ __html: t('home.txtCopyRight') }} />
            </Col>

            <Col xs={{ order: 2 }} sm={{ order: 3 }}>
              <div className="footer__bottom-left">
                <Link href="/service-terms">
                  <a>{t('home.txtTermService')}</a>
                </Link>
                <Link href="/about/privacy-policy">
                  <a>{t('home.txtPolicy')}</a>
                </Link>
                <Link href={`${getSiteUrl('/sitemap.xml')}`}>
                  <a target="_blank">{t('common.txtSitemap')}</a>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default FooterComponentV2;
