import { TFunction } from 'next-i18next';
import { careersSiteUrl } from './constant';

export enum MENU_SERVICE_ITEM_KEY {
  DEVELOPMENT_SERVICES = 'development-services',
  BLOCKCHAIN = 'blockchain',
  EMERGING_TECHNOLOGIES = 'emerging-technologies',
  ADVISORY = 'advisory',
  HIRE_DEVELOPERS = 'hire-developers',
  OUTSOURCING_SERVICE = 'outsourcing-services',
  STAFF_AUGMENTATION = 'staff-augmentation',
}

export enum MENU_ADVISORY {
  DX_CONSULTING = 'dx-consulting',
  IT_CONSULTING = 'it-consulting',
  BLOCKCHAIN_CONSULTING = 'blockchain-consulting',
}

export enum HIRE_DEVELOPERS {
  DEDICATED_TEAM = 'dedicated-teams',
  STAFF_AUGMENTATION = 'staff-augmentation',
  MANAGED_SERVICES = 'managed-services',
}

const MENU_PRODUCTS_ITEM_KEY = {
  WHITE_LABEL: 'whitelabel-blockchain-solutions',
  AI_DEVELOP: 'ai-development',
};

const MENU_COMBANY_KEY = {
  ABOUT_US: 'about-us',
  LEADERSHIP: 'leadership',
  WHY_EKOIOS: 'why-ekoios',
  PORFOLIOS: 'porfolios',
  EBOOK: 'ebook',
  BLOG: 'blog',
  CAREERS: 'careers',
  CONTACT_US: 'contact-us',
  PARTNERSHIP: 'partnership',
};

const menuAboutUsItems = (t) => [
  {
    id: 'about-ekoios',
    thumbnail: '',
    title: t('common.about'),
    description: '',
    path: '/about',
    linkLearnMore: '',
    isRowReverse: false,
    isExternal: false,
  },
  {
    id: MENU_COMBANY_KEY.LEADERSHIP,
    thumbnail: '',
    title: t('common.txtLeadership'),
    description: '',
    path: '/leadership',
    linkLearnMore: '',
    isRowReverse: false,
    isExternal: false,
  },
  {
    id: MENU_COMBANY_KEY.PARTNERSHIP,
    thumbnail: '',
    title: t('common.txtPartnership'),
    description: '',
    path: '/partnership',
    linkLearnMore: '',
    isRowReverse: false,
    isExternal: false,
  },
];

const menuServiceItems = (t: TFunction, isFooter?: boolean) => [
  {
    id: MENU_SERVICE_ITEM_KEY.DEVELOPMENT_SERVICES,
    title: t('service.txtCustomSoftwareDevelopment'),
    linkLearnMore: '',
    description: '',
    thumbnail: '',
    isRowReverse: false,
    isExternal: false,
    childServices: [
      {
        name: t('service.txtCustomSoft'),
        link: '/services/custom-software-development',
        description: '',
        image: '',
        isExternal: false,
      },
      {
        name: t('service.txtWebsiteAndWebApplication'),
        link: '/services/web-development',
        description: '',
        image: '',
        isExternal: false,
      },
      {
        name: t('service.txtMobileApplication'),
        link: '/services/mobile-app-development',
        description: '',
        image: '',
        isExternal: false,
      },
      {
        name: t('service.txtDigitalProductDesign'),
        link: '/services/digital-product-design',
        description: '',
        image: '',
        isExternal: false,
      },
      {
        name: isFooter ? t('service.txtQualityAssuranceTestingFooter') : t('service.txtQualityAssuranceTesting'),
        link: '/services/software-testing-and-quality-assurance',
        description: '',
        image: '',
        isExternal: false,
      },
      {
        name: t('service.textLegacyMigration'),
        link: '/services/legacy-migration',
        description: '',
        image: '',
        isExternal: false,
      },
    ],
  },
  {
    id: MENU_SERVICE_ITEM_KEY.EMERGING_TECHNOLOGIES,
    title: t('service.advanced_technology'),
    description: '',
    linkLearnMore: '',
    isRowReverse: false,
    thumbnail: '',
    childServices: [
      {
        name: t('service.txtBlockchain'),
        link: '/services/blockchain-development',
        description: '',
        image: '',
        isExternal: false,
      },
      {
        name: isFooter ? t('service.txtArtificialIntelligenceFooter') : t('service.txtArtificialIntelligence'),
        link: '/services/ai-development',
        description: '',
        image: '',
        isExternal: false,
      },
      {
        name: t('service.txtCloud'),
        link: '/services/cloud-development',
        description: '',
        image: '',
        isExternal: false,
      },
      {
        name: t('service.txtARVR'),
        link: '/services/ar-vr-app-development',
        description: '',
        image: '',
        isExternal: false,
      },
      {
        name: t('service.txtIOT'),
        link: '/services/iot-software-development',
        description: '',
        image: '',
        isExternal: false,
      },
    ],
  },

  {
    id: MENU_SERVICE_ITEM_KEY.HIRE_DEVELOPERS,
    title: t('service.staffing_services'),
    description: '',
    linkLearnMore: '',
    isRowReverse: false,
    thumbnail: '',
    childServices: [
      {
        name: t('service.txtDedicatedTeam'),
        link: '/services/dedicated-development-team',
        description: '',
        image: '',
        isExternal: false,
      },
      {
        name: t('service.txtStaffAugmentation'),
        link: '/services/it-staff-augmentation',
        description: '',
        image: '',
        isExternal: false,
      },
    ],
  },
  {
    id: MENU_SERVICE_ITEM_KEY.ADVISORY,
    title: t('service.readyMakeSolutions'),
    description: '',
    linkLearnMore: '',
    isRowReverse: false,
    thumbnail: '',
    childServices: [
      {
        name: t('common.txtAI'),
        link: '',
        description: '',
        image: '',
        isExternal: false,
        items: [
          {
            name: t('service.ai_chatbot'),
            link: '',
            description: '',
            image: '',
            isExternal: false,
          },
          {
            name: t('service.video_analytics'),
            link: '',
            description: '',
            image: '',
            isExternal: false,
          },
          {
            name: t('service.smart_surveillance_camera'),
            link: '',
            description: '',
            image: '',
            isExternal: false,
          },
          {
            name: t('service.video_restoration'),
            link: '',
            description: '',
            image: '',
            isExternal: false,
          },
        ],
      },
      {
        name: t('service.live_streaming'),
        link: '',
        description: '',
        image: '',
        isExternal: false,
        items: [
          {
            name: t('service.social_platform'),
            link: '',
            description: '',
            image: '',
            isExternal: false,
          },
          {
            name: t('service.elearning_platform'),
            link: '',
            description: '',
            image: '',
            isExternal: false,
          },
        ],
      },
      {
        name: t('common.txtWeb3'),
        link: '',
        description: '',
        image: '',
        isExternal: false,
        items: [...menuBlockchainItems(t).childServices],
      },
    ],
  },
];

const menuBlockchainItems = (t: TFunction) => ({
  id: MENU_SERVICE_ITEM_KEY.BLOCKCHAIN,
  thumbnail: '',
  title: t('service.txtBlockchain'),
  description: '',
  linkLearnMore: '',
  isRowReverse: false,
  childServices: [
    {
      name: t('service.txtSmartContract'),
      link: '/smart-contract-development',
      description: '',
      isExternal: false,
    },
    {
      name: t('service.txtDecentralizedApp'),
      link: '/dapp-development',
      description: '',
      isExternal: false,
    },
    {
      name: t('service.txtWeb3Game'),
      link: '/web3-game-development',
      description: '',
      isExternal: false,
    },
    {
      name: t('service.textNFTMarketplace'),
      link: '/white-label-nft-marketplace',
      description: '',
      isExternal: false,
    },
    {
      name: t('service.txtDecentralizedExchange'),
      link: '/decentralized-cryptocurrency-exchange-development',
      description: '',
      isExternal: false,
    },
    {
      name: t('service.txtCentralizedExchange'),
      link: '/centralized-cryptocurrency-exchange-development',
      description: '',
      isExternal: false,
    },
    {
      name: t('service.txtCryptoWallet'),
      link: '/white-label-crypto-wallet',
      description: '',
      isExternal: false,
    },
    {
      name: t('service.txtCryptoStaking'),
      link: '/defi-staking-platform-development',
      description: '',
      isExternal: false,
    },
    {
      name: t('service.txtAssetTokenization'),
      link: '/tokenization-development',
      description: '',
      isExternal: false,
    },
    {
      name: t('service.txtTokenVesting'),
      link: '/white-label-token-vesting-portal',
      description: '',
      isExternal: false,
    },
    {
      name: t('service.txtMarketMaking'),
      link: '/',
      description: '',
      isExternal: false,
    },
  ],
});

const menuProductItems = (t) => [
  {
    id: MENU_PRODUCTS_ITEM_KEY.WHITE_LABEL,
    thumbnail: '',
    title: t('product.txtWhiteLabelBlockchainSolutions'),
    description: '',
    path: '/products',
    linkLearnMore: '',
    isRowReverse: false,
    isExternal: false,
    childServices: [
      {
        image: '/icons/_product/icon-vesting-tool.svg',
        name: t('common.txtCentralizedCryptoExchange'),
        description: t('product.subSolutionFive'),
        link: '/centralized-cryptocurrency-exchange-development',
      },
      {
        image: '/icons/_product/icon-vesting-tool.svg',
        name: t('common.txtDecentralizedCryptoExchange'),
        description: t('product.subSolutionFive'),
        link: '/decentralized-cryptocurrency-exchange-development',
      },
      {
        image: '/icons/_product/icon-crypto-wallet.svg',
        name: t('product.txtCryptoWallet'),
        description: t('product.subSolutionTwo'),
        link: '/white-label-crypto-wallet',
      },
      {
        image: '/icons/_product/icon-marketplace.svg',
        name: t('product.txtNFTMarketplace'),
        description: t('product.subSolutionThree'),
        link: '/white-label-nft-marketplace',
      },
      {
        image: '/icons/_product/icon-vesting-tool.svg',
        name: t('product.txtTokenVesting'),
        description: t('product.subSolutionFive'),
        link: '/white-label-token-vesting-portal',
      },
      {
        image: '/icons/_product/icon-staking.svg',
        name: t('product.txtStakingPlatform'),
        description: t('product.subSolutionFour'),
        link: '/defi-staking-platform-development',
      },
      {
        image: '/icons/_product/icon-minting.svg',
        name: t('product.txtNFTMintingSystem'),
        description: t('product.subSolutionSix'),
        link: '/white-label-nft-minting-platform',
      },
      {
        image: '/icons/_product/icon-staking.svg',
        name: t('product.txtCross-chainBridges'),
        description: t('product.subSolutionFour'),
        link: '/white-label-cross-chain-bridge',
      },
      {
        image: '/icons/_product/icon-minting.svg',
        name: t('product.txtCryptoLaunchpads'),
        description: t('product.subSolutionSix'),
        link: '/white-label-launchpad-development',
      },
    ],
  },
  {
    id: MENU_PRODUCTS_ITEM_KEY.AI_DEVELOP,
    thumbnail: '/video/intro-ai-2.mp4',
    title: t('service.serviceAI'),
    description: t('service.serviceAIDescription'),
    path: 'https://etheia.ai/',
    linkLearnMore: '/services/ai-development',
    isRowReverse: true,
    isExternal: true,
    childServices: [
      {
        name: t('product.etheiaVideoAnalyticsSystem'),
        link: 'https://etheia.ai',
        image: '/icons/_services/ai/icon-computer.svg',
        description: t('service.subAIConsulting'),
        linkProps: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    ],
  },
];

const menuResourceItems = (t) => [
  {
    id: 'blog',
    thumbnail: '',
    title: t('resources.txtBlog'),
    description: '',
    path: '/news',
    linkLearnMore: '',
    isRowReverse: false,
    isExternal: false,
  },
  {
    id: 'ebook',
    thumbnail: '',
    title: t('resources.txtEbook'),
    description: '',
    path: '/ebooks',
    linkLearnMore: '',
    isRowReverse: false,
    isExternal: false,
  },
];

const menuIndustryItems = (t) => [
  {
    id: 'fintech',
    thumbnail: '',
    title: t('industry.txtFintech'),
    description: '',
    path: '/fintech-software-development-services',
    linkLearnMore: '',
    isRowReverse: false,
    isExternal: false,
  },

  // {
  //   id: 'travel',
  //   thumbnail: '',
  //   title: t('industry.txtTravel'),
  //   description: '',
  //   path: '',
  //   linkLearnMore: '',
  //   isRowReverse: false,
  //   isExternal: false,
  // },
  {
    id: 'commerce',
    thumbnail: '',
    title: t('industry.e-commerce'),
    description: '',
    path: '/ecommerce-software-development',
    linkLearnMore: '',
    isRowReverse: false,
    isExternal: false,
  },
  {
    id: 'entertainment',
    thumbnail: '',
    title: t('service.entertainment'),
    description: '',
    path: '/entertainment-software-development',
    linkLearnMore: '',
    isRowReverse: false,
    isExternal: false,
  },
  // {
  //   id: 'logistics',
  //   thumbnail: '',
  //   title: t('home.txtLogistics'),
  //   description: '',
  //   path: '/logistics-software-development',
  //   linkLearnMore: '',
  //   isRowReverse: false,
  //   isExternal: false,
  // },
];

const menuAdvisoryItems = (t) => [
  // {
  //   id: MENU_ADVISORY.DX_CONSULTING,
  //   title: t('service.txtDXConsulting'),
  //   path: '/',
  //   isExternal: false,
  // },
  {
    id: MENU_ADVISORY.IT_CONSULTING,
    title: t('service.txtITConsulting'),
    path: '/it-consulting',
    isExternal: false,
  },
  {
    id: MENU_ADVISORY.BLOCKCHAIN_CONSULTING,
    title: t('tag.blockchain_consulting'),
    path: '/blockchain-consulting',
    isExternal: false,
  },
];

const menuHireDevelopers = (t: TFunction) => [
  {
    id: HIRE_DEVELOPERS.DEDICATED_TEAM,
    title: t('service.txtDedicatedTeam'),
    path: '/',
    isExternal: false,
  },
  {
    id: HIRE_DEVELOPERS.STAFF_AUGMENTATION,
    title: t('service.txtStaffAugmentation'),
    path: '/',
    isExternal: false,
  },
  {
    id: HIRE_DEVELOPERS.MANAGED_SERVICES,
    title: t('service.txtManagedServices'),
    path: '/',
    isExternal: false,
  },
];

const menuCombanyItems = (t) => [
  {
    id: MENU_COMBANY_KEY.ABOUT_US,
    title: t('about-us.txtAboutEkotek'),
    path: '/about',
    isExternal: false,
  },
  {
    id: MENU_COMBANY_KEY.PARTNERSHIP,
    title: t('common.txtPartnership'),
    path: '/partnership',
    isExternal: false,
  },
  // {
  //   id: MENU_COMBANY_KEY.WHY_EKOIOS,
  //   title: t('combany.txtWhyEkoios'),
  //   path: '',
  //   isExternal: false,
  // },

  {
    id: MENU_COMBANY_KEY.EBOOK,
    title: t('combany.txtEbook'),
    path: '/ebooks',
    isExternal: false,
  },
  {
    id: MENU_COMBANY_KEY.BLOG,
    title: t('combany.txtBlog'),
    path: '/news',
    isExternal: false,
  },
  {
    id: MENU_COMBANY_KEY.CAREERS,
    title: t('combany.txtCareers'),
    path: careersSiteUrl,
    isExternal: true,
    rel: 'nofollow',
  },
  {
    id: MENU_COMBANY_KEY.CONTACT_US,
    title: t('combany.txtContactUs'),
    path: '/contact',
    isExternal: false,
  },
];

export {
  menuAboutUsItems,
  menuProductItems,
  menuResourceItems,
  menuIndustryItems,
  menuCombanyItems,
  menuHireDevelopers,
  menuAdvisoryItems,
  menuServiceItems,
  MENU_PRODUCTS_ITEM_KEY,
  menuBlockchainItems,
};
