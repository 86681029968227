import { WithRouterProps } from 'next/dist/client/with-router';
import { NextRouter, withRouter } from 'next/router';
import React, { useEffect } from 'react';

import ModalComplete from '@components/ModalComplete';
import { ModalProvider } from '@context/provider/modal';

import { useDelayTimer } from '@common/hook';
import BackTopComponent from '@components/BackTop';
import CookieSetting from '@components/CookieSetting';
import FooterComponentV2 from '@components/_v2/Footer';
import HeaderComponentV2 from '@components/_v2/Header';
import classNames from 'classnames';
import {
  cleanGoogleRecaptcha,
  cleanHubspotScript,
  injectGoogleReCaptchaScript,
  injectHubspotScript,
} from 'utils';

interface Router extends NextRouter {
  path: string;
  breadcrumbName: string;
}

interface Props extends WithRouterProps {
  router: Router;
  className?: string;
  headerProps?: any;
  isMainLayout?: boolean;
}

const LayoutApp = (props: React.PropsWithChildren<Props>) => {
  const isDelayTime = useDelayTimer(10000);

  const { children, className, headerProps, isMainLayout = false } = props;

  useEffect(() => {
    if (!isDelayTime) {
      injectGoogleReCaptchaScript({
        siteKey: process.env.NEXT_PUBLIC_CAPTCHA_KEY,
        useEnterprise: false,
        useRecaptchaNet: true,
        scriptProps: { async: true, defer: true, appendTo: 'body' },
      });
      injectHubspotScript();
    }

    return () => {
      cleanGoogleRecaptcha('google-recaptcha-v3');
      cleanHubspotScript();
    };
  }, [isDelayTime]);

  return (
    <ModalProvider>
      <div style={{ minHeight: '100vh' }} className={className}>
        <HeaderComponentV2 {...headerProps} />
        <div
          className={classNames('site-layout-background', {
            'main-layout': !isMainLayout,
          })}
        >
          {children}
        </div>

        <FooterComponentV2 />

        <BackTopComponent />

        <ModalComplete />

        <CookieSetting />
      </div>
    </ModalProvider>
  );
};

export default withRouter(LayoutApp);
