import Image from 'next/image';

interface SectionTitleInterface {
  title: string;
  onClick: any;
  dropdownVisible: boolean;
}

export const SectionTitle = ({
  title,
  onClick,
  dropdownVisible,
}: SectionTitleInterface) => {
  return (
    <>
      <div className="section-title">{title}</div>
      <div className="section-title-mobile" onClick={onClick}>
        <p>{title}</p>

        {dropdownVisible ? (
          <div className="icon-top-arrow">
            <Image
              src="/icons/top-arrows.svg"
              alt="top down"
              width={20}
              height={12}
            />
          </div>
        ) : (
          <div className="icon-down-arrow">
            <Image
              src="/icons/down-arrow-white.svg"
              alt="top down"
              width={20}
              height={12}
            />
          </div>
        )}
      </div>
    </>
  );
};
