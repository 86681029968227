import classNames from 'classnames';
import Image from 'next/image';
import { BackTop } from 'antd';

interface BackTopInterface {
  className?: string;
  icon?: string;
}

const BackTopComponent = ({ className, icon }: BackTopInterface) => {
  return (
    <BackTop>
      <div className={classNames('ant-back-top-inner', className)}>
        <Image
          src={icon || '/icons/back-top-white.svg'}
          alt="arrow-icon"
          width={24}
          height={24}
        />
      </div>
    </BackTop>
  );
};

export default BackTopComponent;
