import { ButtonPrimary } from '@components/Button';
import { Menu } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, memo } from 'react';
import { MenuPopover } from '../PopoverMenu';
import Search from '../Search';
import SelectLanguage from '@components/SelectLanguage';

interface DesktopHeaderInterface {
  logoLink: string;
  isHeaderLight: boolean;
  visibleHeaderTiny: boolean;
  menus: any[];
  setDrawerVisible: any;
}

const { Item } = Menu;

const DesktopHeader = ({
  logoLink,
  isHeaderLight,
  visibleHeaderTiny,
  menus,
  setDrawerVisible,
}: DesktopHeaderInterface) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { pathname } = router;

  return (
    <div className="container-v2">
      <Link href={logoLink || '/'}>
        <a className="header__logo">
          <Image
            src={isHeaderLight && !visibleHeaderTiny ? '/images/logo-light.svg' : '/images/logo.svg'}
            alt="Ekotek"
            layout="fill"
            className="image"
            quality={100}
          />
        </a>
      </Link>
      <Menu mode="horizontal" className="header__menu" expandIcon={<img src="/icons/icon-right-arrow.svg" />}>
        {menus.map((item, index) => (
          <Fragment key={index}>
            {item.subMenu ? (
              <MenuPopover isItemLast={index === menus.length - 1} item={item} className="popover-custom" />
            ) : (
              <Item
                key={Math.random()}
                className={classNames({
                  'item-active': item.path === pathname,
                  'item-last': index === menus.length - 1,
                })}
              >
                {item?.targetBlank ? (
                  <Link href={item.path}>
                    <a target="_blank" rel="noopener noreferrer">
                      {item.title}
                    </a>
                  </Link>
                ) : (
                  <Link href={item.path}>
                    <a>{item.title}</a>
                  </Link>
                )}
              </Item>
            )}
          </Fragment>
        ))}
      </Menu>

      <div className="header__right">
        <Search
          isHeaderLight={isHeaderLight}
          setDrawerVisible={setDrawerVisible}
          visibleHeaderTiny={visibleHeaderTiny}
        />
        <SelectLanguage />
        <Link href="/contact">
          <a>
            <ButtonPrimary isSquareBorder isRawColorBackground>
              {t('common.buttonContactUs')}
            </ButtonPrimary>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default memo(DesktopHeader);
