import { ButtonPrimary } from '@components/Button';
import { Grid } from 'antd';
import { useTimerHook } from 'hooks';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { getCookie, setCookie } from 'utils';

const { useBreakpoint } = Grid;

const TIME = 5;

const CookieSetting = () => {
  const { md } = useBreakpoint();
  const { t } = useTranslation();

  const { isValidTime } = useTimerHook(TIME);

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const isAcceptCookie = getCookie('accept-cookie');
    const isTrue = isAcceptCookie === 'true';
    setIsVisible(isTrue);
  }, []);

  const handleAcceptCookie = () => {
    setCookie('accept-cookie', 'true', 60);
    setIsVisible(true);
  };

  if (isVisible) {
    return null;
  }

  return (
    <div className="cookie-setting" hidden={!md && isValidTime}>
      <div className="container">
        <div
          className="left"
          dangerouslySetInnerHTML={{ __html: t('policy.settingDescription') }}
        />
        <div className="right">
          <Link href="/cookies-policy">
            <a onClick={handleAcceptCookie}>
              <span>{t('policy.cookiesPolicies')}</span>
            </a>
          </Link>
          <ButtonPrimary onClick={handleAcceptCookie}>
            {t('policy.dismiss')}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default CookieSetting;
