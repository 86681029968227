import { IconDownArrow } from '@public/icons';
import { Select } from 'antd';
import { useRouter } from 'next/router';
import { setCookie } from 'utils';

const { Option } = Select;

const SelectLanguage = () => {
  const router = useRouter();
  const { locale, pathname, query, asPath } = router;

  const handleChange = (value: string) => {
    setCookie('language', value);
    router.push({ pathname, query }, asPath, { locale: value || 'en' });
  };

  return (
    <div className="select-language">
      <Select
        defaultValue={locale || 'en'}
        onChange={handleChange}
        suffixIcon={<IconDownArrow />}
        dropdownMatchSelectWidth={120}
      >
        <Option value="en">
          <img src="/icons/services/en-flag.svg" alt="EN" /> English
        </Option>
        <Option value="ja">
          <img src="/icons/services/jp-flag-2.svg" alt="JP" /> 日本語
        </Option>
      </Select>
    </div>
  );
};

export default SelectLanguage;
