import { IconHamburger } from '@public/icons';
import Image from 'next/image';
import Link from 'next/link';
import Search from '../Search';
import SelectLanguage from '@components/SelectLanguage';

interface MobileHeaderInterface {
  logoLink: string;
  isHeaderLight: boolean;
  visibleHeaderTiny: boolean;
  setDrawerVisible: any;
  toggleMenu: any;
  activeMenuMobile: boolean;
  setActiveMenuMobile: any;
}

const MobileHeader = ({
  logoLink,
  isHeaderLight,
  visibleHeaderTiny,
  setDrawerVisible,
  toggleMenu,
  activeMenuMobile,
  setActiveMenuMobile,
}: MobileHeaderInterface) => {
  return (
    <div className="container-v2">
      <Link href={logoLink || '/'}>
        <a>
          <div className="header__logo">
            <Image
              src={isHeaderLight && !visibleHeaderTiny ? '/images/logo-light.svg' : '/images/logo.svg'}
              alt="Ekotek"
              layout="fill"
              className="image"
              quality={100}
            />
          </div>
        </a>
      </Link>
      <div className="btn-menu-mobile">
        <Search
          isHeaderLight={isHeaderLight}
          setDrawerVisible={setDrawerVisible}
          visibleHeaderTiny={visibleHeaderTiny}
        />
        <SelectLanguage />
        <span onClick={toggleMenu}>
          <IconHamburger activeMenuMobile={activeMenuMobile} setActiveMenuMobile={setActiveMenuMobile} />
        </span>
      </div>
    </div>
  );
};

export default MobileHeader;
