import { Col, Popover, Row } from 'antd';
import Link from 'next/link';
import { useState } from 'react';

export const POPOVER_MENU_TYPE = {
  EXTEND_LIST: 1,
  NORMAL_LIST: 2,
};

interface MenuInterface {
  list: any[];
  type?: number;
  onVisible?: any;
}

const Menu = ({ list, type = POPOVER_MENU_TYPE.NORMAL_LIST, onVisible }: MenuInterface) => {
  const handleChangeVisible = () => onVisible(false);
  const [activeIndex, setActiveIndex] = useState(undefined);

  const toggleSubmenuPopover = (i?: number, index?: number) => {
    if (i === 3) {
      setActiveIndex(index);
    } else {
      setActiveIndex(undefined);
    }
  };

  return (
    <div className="menu-popover">
      {type === POPOVER_MENU_TYPE.EXTEND_LIST ? (
        <Row className="menu-popover__extend" gutter={[60, 30]}>
          {list?.map(({ title, items }, i) => (
            <Col className="menu-popover__content" key={title}>
              <p className="menu-popover__content--title">{title}</p>
              <div className="menu-popover__content--body">
                {!!items &&
                  items?.map(({ name, link, linkProps, items }, index) => (
                    <div
                      className="item"
                      key={name}
                      onClick={handleChangeVisible}
                      onMouseMove={() => toggleSubmenuPopover(i, index)}
                      onMouseLeave={() => toggleSubmenuPopover()}
                    >
                      {link ? (
                        <Link href={link}>
                          <a {...linkProps}>{name}</a>
                        </Link>
                      ) : (
                        <Popover
                          placement="right"
                          visible={activeIndex === index}
                          content={
                            <div className="menu-popover__content--body">
                              {items?.map(({ name, link, linkProps }) => (
                                <div key={link} className="item" onClick={() => toggleSubmenuPopover()}>
                                  {link ? (
                                    <Link href={link}>
                                      <a {...linkProps}>{name}</a>
                                    </Link>
                                  ) : (
                                    <span>{name}</span>
                                  )}
                                </div>
                              ))}
                            </div>
                          }
                          trigger="hover"
                          overlayClassName="popover-submenu"
                        >
                          <span>{name}</span>
                        </Popover>
                      )}
                      {!!items && <img src="/icons/right-arrow-blue-2.svg" alt="right arrow" />}
                    </div>
                  ))}
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <div className="menu-popover__normal">
          {list?.map(({ title, path }) => (
            <div className="menu-popover__normal--item" key={title} onClick={handleChangeVisible}>
              <Link href={path}>
                <a>{title}</a>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Menu;
