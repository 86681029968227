import { useEffect, useState } from 'react';

const useTimerHook = (duration) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const time = setInterval(() => {
      setTimer(timer + 1);
    }, 1000);

    if (timer > duration) {
      clearInterval(time);
    }
    return () => {
      clearTimeout(time);
    };
  }, [timer]);

  return {
    timer,
    isValidTime: timer < duration,
  };
};

export { useTimerHook };
