import { Grid, Popover } from "antd";
import Image from "next/image";
import { useState } from "react";
import PopoverSearch from "../PopoverSearch";

interface SearchInterface {
  isHeaderLight: boolean;
  visibleHeaderTiny: boolean;
  setDrawerVisible: any;
}

const Search = ({ isHeaderLight, visibleHeaderTiny, setDrawerVisible }: SearchInterface) => {
  const [visiblePopoverSearch, setVisiblePopoverSearch] = useState(false);
  const { useBreakpoint } = Grid;
  const { xl } = useBreakpoint();
  const isMobile = !xl;

  return (
    <Popover
      placement={isMobile ? 'bottom' : 'bottomRight'}
      content={
        <PopoverSearch
          setVisible={setDrawerVisible}
          setVisiblePopoverSearch={setVisiblePopoverSearch}
        />
      }
      trigger="click"
      getPopupContainer={() => document.querySelector('header')}
      visible={visiblePopoverSearch}
      onVisibleChange={(visible) => setVisiblePopoverSearch(visible)}
      className="icon-search"
    >
      <Image
        src={
          isHeaderLight && !visibleHeaderTiny
            ? '/icons/icon-search-white.svg'
            : '/icons/icon-search-black.svg'
        }
        alt="Icon search"
        width={24}
        height={24}
        objectFit="contain"
        quality={100}
      />
    </Popover>
  );
};

export default Search;