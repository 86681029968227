import { Grid, Layout } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { memo, useEffect, useState } from 'react';

import { menuAboutUsItems, menuIndustryItems, menuResourceItems, menuServiceItems } from '@common/menuData';
import { getCookie } from 'utils';
import DesktopHeader from './Desktop';
import HeaderDrawer from './Drawer';
import { POPOVER_MENU_TYPE } from './Menu';
import MobileHeader from './Mobile';

const { Header } = Layout;
const { useBreakpoint } = Grid;

const urlHeaderLight = [
  '/',
  // '/services/digital-product-design',
  // '/services/quality-assurance-testing',
];

const HeaderComponentV2 = ({ menu, logoLink, ...props }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { pathname } = router;
  const [visible, setVisible] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [itemMenu, setItemMenu] = useState<any>();
  const [visibleHeaderTiny, setVisibleHeaderTiny] = useState(false);
  const [activeMenuMobile, setActiveMenuMobile] = useState(true);
  const [activePromoBar, setActivePromoBar] = useState(true);

  const { xl } = useBreakpoint();
  const isMobile = !xl;

  const isHeaderLight = urlHeaderLight.includes(pathname);

  const menus = menu || [
    {
      title: t('common.services'),
      path: '/services',
      isBigMenu: true,
      popoverMenuType: POPOVER_MENU_TYPE.EXTEND_LIST,
      subMenu: menuServiceItems(t).map((item) => ({
        title: item.title,
        path: item.linkLearnMore,
        items: item.childServices,
      })),
    },
    {
      title: t('common.portfolio'),
      path: '/portfolios',
      isBigMenu: false,
    },
    {
      title: t('industry.industry'),
      path: '',
      isBigMenu: true,
      popoverMenuType: POPOVER_MENU_TYPE.NORMAL_LIST,
      subMenu: menuIndustryItems(t).map((item) => ({
        title: item.title,
        path: item.path,
        items: null,
      })),
    },
    {
      title: t('common.txtResources'),
      path: '',
      isBigMenu: true,
      popoverMenuType: POPOVER_MENU_TYPE.NORMAL_LIST,
      subMenu: menuResourceItems(t).map((item) => ({
        title: item.title,
        path: item.path,
        items: null,
      })),
    },
    {
      title: t('common.aboutUs'),
      path: '',
      isBigMenu: true,
      popoverMenuType: POPOVER_MENU_TYPE.NORMAL_LIST,
      subMenu: menuAboutUsItems(t).map((item) => ({
        title: item.title,
        path: item.path,
        items: null,
      })),
    },
  ];

  const toggleMenu = () => {
    setVisible((prevState) => !prevState);
    setShowSubMenu(false);
  };

  const handleShowSubMenu = (item) => {
    setItemMenu(item);
    setShowSubMenu(true);
  };

  useEffect(() => {
    if (!isMobile) {
      setVisible(false);
      setActiveMenuMobile(true);
    }
  }, [isMobile]);

  const linkDirectSubmenu = (element, props = null) => {
    if (props && typeof window !== 'undefined') {
      window.open(element.link, '_ blank');
    } else {
      router.push({
        pathname: element.link,
      });
    }

    setVisible(false);
    setActiveMenuMobile(true);
  };

  useEffect(() => {
    const onScroll = () => setVisibleHeaderTiny(window.pageYOffset > 50);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    const isEmpty = getCookie('active-promo-bar');
    setActivePromoBar(!isEmpty);
  }, []);

  return (
    <>
      <Header
        className={classNames('header-v2', {
          'header-tiny': visibleHeaderTiny,
          'header-light': isHeaderLight,
          'header-promo': !activePromoBar,
        })}
        {...props}
      >
        {isMobile ? (
          <MobileHeader
            isHeaderLight={isHeaderLight}
            logoLink={logoLink}
            setDrawerVisible={setVisible}
            toggleMenu={toggleMenu}
            visibleHeaderTiny={visibleHeaderTiny}
            activeMenuMobile={activeMenuMobile}
            setActiveMenuMobile={setActiveMenuMobile}
          />
        ) : (
          <DesktopHeader
            isHeaderLight={isHeaderLight}
            logoLink={logoLink}
            menus={menus}
            setDrawerVisible={setVisible}
            visibleHeaderTiny={visibleHeaderTiny}
          />
        )}
        <HeaderDrawer
          activePromoBar={activePromoBar}
          drawerVisible={visible}
          handleShowSubMenu={handleShowSubMenu}
          itemMenu={itemMenu}
          linkDirectSubmenu={linkDirectSubmenu}
          menus={menus}
          pathname={pathname}
          setActiveMenuMobile={setActiveMenuMobile}
          setShowSubMenu={setShowSubMenu}
          setVisible={setVisible}
          showSubMenu={showSubMenu}
          toggleMenu={toggleMenu}
          visibleHeaderTiny={visibleHeaderTiny}
        />
      </Header>
    </>
  );
};

export default memo(HeaderComponentV2);
