import { IconDot } from '@public/icons';
import { Collapse, Drawer, Menu } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Fragment } from 'react';

interface HeaderDrawerInterface {
  toggleMenu: any;
  drawerVisible: boolean;
  visibleHeaderTiny: boolean;
  activePromoBar: boolean;
  showSubMenu: boolean;
  menus: any[];
  pathname: string;
  setVisible: any;
  setActiveMenuMobile: any;
  handleShowSubMenu: any;
  setShowSubMenu: any;
  itemMenu: any;
  linkDirectSubmenu: any;
}

const { Panel } = Collapse;

const HeaderDrawer = ({
  toggleMenu,
  drawerVisible,
  visibleHeaderTiny,
  activePromoBar,
  showSubMenu,
  menus,
  pathname,
  setVisible,
  setActiveMenuMobile,
  handleShowSubMenu,
  setShowSubMenu,
  itemMenu,
  linkDirectSubmenu,
}: HeaderDrawerInterface) => {
  const { t } = useTranslation('common');

  return (
    <Drawer
      placement="right"
      onClose={toggleMenu}
      visible={drawerVisible}
      width="100vw"
      closable={false}
      className={classNames('drawer-menu-v2', {
        'drawer-menu-scroll-v2': visibleHeaderTiny,
        'drawer-menu-promo': !activePromoBar,
      })}
    >
      <div className="menu-mobile">
        {!showSubMenu ? (
          <Menu>
            {menus.map((item) => {
              if (item.subMenu) {
                return (
                  <div key={Math.random()} className="submenu">
                    <Link href={item.path}>
                      <a
                        className={classNames('name', {
                          'item-active': item.path === pathname,
                        })}
                        onClick={() => {
                          setVisible((prev) => !prev), setActiveMenuMobile(true);
                        }}
                      >
                        {item.title}
                      </a>
                    </Link>
                    <span onClick={() => handleShowSubMenu(item)}>
                      {item.path === pathname ? (
                        <img
                          className="icon-arrow icon-arrow-short"
                          src="/icons/arrow-right-linear-short.svg"
                          alt="Right Arrow"
                        ></img>
                      ) : (
                        <img src="/icons/arrow-right-black.svg" alt="icon arrow right" />
                      )}
                    </span>
                  </div>
                );
              } else {
                return (
                  <div className="link" key={Math.random()}>
                    <Link
                      href={{
                        pathname: item.path,
                      }}
                    >
                      <a
                        className={classNames({
                          'item-active': item.path === pathname,
                        })}
                        onClick={() => {
                          setVisible(false), setActiveMenuMobile(true);
                        }}
                      >
                        <div>{item.title}</div>
                        {item.path === pathname ? (
                          <img
                            className="icon-arrow icon-arrow-short"
                            src="/icons/arrow-right-linear-short.svg"
                            alt="Right Arrow"
                          ></img>
                        ) : (
                          <img src="/icons/arrow-right-black.svg" alt="icon arrow right" />
                        )}
                      </a>
                    </Link>
                  </div>
                );
              }
            })}
            <div className="link" key={Math.random()}>
              <Link
                href={{
                  pathname: '/contact',
                }}
              >
                <a
                  className={classNames({
                    'item-active': pathname === '/contact',
                  })}
                  onClick={() => {
                    setVisible(false), setActiveMenuMobile(true);
                  }}
                >
                  <div>{t('common.txtContactUs')}</div>
                  {pathname === '/contact' ? (
                    <img
                      className="icon-arrow icon-arrow-short"
                      src="/icons/arrow-right-linear-short.svg"
                      alt="Right Arrow"
                    ></img>
                  ) : (
                    <img src="/icons/arrow-right-black.svg" alt="icon arrow right" />
                  )}
                </a>
              </Link>
            </div>
          </Menu>
        ) : (
          <div className="show-submenu">
            <div className="top">
              <div className="back" onClick={() => setShowSubMenu(false)}>
                <img src="/icons/arrow-left-linear.svg" alt="icon arrow right" />
              </div>
              <div className="title">{itemMenu && itemMenu.title}</div>
            </div>

            <div className="collapse">
              <Collapse
                accordion={true}
                ghost
                expandIconPosition="right"
                destroyInactivePanel={true}
                expandIcon={({ isActive }) => {
                  return isActive ? (
                    <img src="/icons/arrow-top-black.svg" alt="Arrow down" className="icon-down-arrow" />
                  ) : (
                    <img src="/icons/arrow-down-black.svg" alt="top down" className="icon-top-arrow" />
                  );
                }}
              >
                {itemMenu.subMenu.map((val, idx) =>
                  val?.items ? (
                    <Panel
                      header={
                        <Link
                          href={{
                            pathname: val.path,
                          }}
                        >
                          <a
                            onClick={() => {
                              setVisible(false), setActiveMenuMobile(true);
                            }}
                          >
                            {val.title}
                          </a>
                        </Link>
                      }
                      key={idx}
                    >
                      {val.items.map((element, key) =>
                        !element?.items ? (
                          <div
                            key={key}
                            onClick={() => linkDirectSubmenu(element, element?.linkProps)}
                            className={classNames(element?.className)}
                          >
                            <IconDot />
                            <span className="name-sub">{element.name}</span>
                          </div>
                        ) : (
                          <div key={key} className="sub">
                            <span className="sub-label">{element?.name}</span>
                            {element.items.map((item, index) => (
                              <div
                                key={index}
                                onClick={() => linkDirectSubmenu(item, item?.linkProps)}
                                className={classNames(item?.className)}
                              >
                                <IconDot />
                                <span className="name-sub">{item.name}</span>
                              </div>
                            ))}
                          </div>
                        ),
                      )}
                    </Panel>
                  ) : (
                    <Panel
                      header={
                        <Link
                          href={{
                            pathname: val.path,
                            query: val.slug && {
                              name: val.slug,
                              page: 1,
                            },
                          }}
                        >
                          <a
                            onClick={() => {
                              setVisible(false), setActiveMenuMobile(true);
                            }}
                          >
                            {val.title}
                          </a>
                        </Link>
                      }
                      key={idx}
                      showArrow={false}
                    />
                  ),
                )}
              </Collapse>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default HeaderDrawer;
