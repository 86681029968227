import { ButtonPrimary } from '@components/Button';
import { FieldTextInput } from '@components/FormItem/Input';
import { Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';

const PopoverSearch = ({ setVisible, setVisiblePopoverSearch }) => {
  const { t } = useTranslation('common');
  const formikRef = useRef(null) as any;
  const router = useRouter();

  const handleSearch = (data) => {
    setVisible(false);
    setVisiblePopoverSearch(false);
    router.push({
      pathname: '/search',
      query: { keyword: data.keyword, page: 1, subtype: 'post' },
    });
  };

  return (
    <div className="content-search">
      <Formik
        initialValues={{
          keyword: '',
        }}
        onSubmit={handleSearch}
        innerRef={formikRef}
      >
        {({ values }) => (
          <Form className="form-search-menu-v2">
            <FieldTextInput
              name="keyword"
              value={values.keyword}
              placeholder={t('home.txtPlaceholderSearch')}
            />
            <ButtonPrimary
              htmlType="submit"
              className="button-submit"
              disabled={!values.keyword}
            >
              <img
                src="/icons/icon-search-orange.svg"
                alt="Icon search"
                className="icon-search"
              />
            </ButtonPrimary>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default PopoverSearch;
