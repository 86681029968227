import { Menu, Popover } from 'antd';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import MenuContent from '../Menu';
import Link from 'next/link';
import { useState } from 'react';
import Image from 'next/image';

interface MenuPopoverInterface {
  item: any;
  isItemLast: boolean;
  className?: string;
}

enum MenuItems {
  SERVICES_EN = 'Services',
  SERVICES_JP = 'サービス',

  RESOURCES_EN = 'Resources',
  RESOURCES_JP = 'リソース',

  INDUSTRY_EN = 'Industry',
  INDUSTRY_JP = 'インダストリー',

  ABOUT_EN = 'About Us',
  ABOUT_JP = '会社概要',
}

const { Item } = Menu;

export const MenuPopover = ({ item, isItemLast, className }: MenuPopoverInterface) => {
  const router = useRouter();
  const { pathname } = router;
  const [visible, setVisible] = useState(false);

  const handleChangeVisible = (visible: boolean) => setVisible(visible);

  return (
    <Popover
      placement="bottomRight"
      visible={visible}
      onVisibleChange={handleChangeVisible}
      overlayClassName={classNames('header-menu-popover', className, {
        'first-menus-dropdown': [MenuItems.SERVICES_EN, MenuItems.SERVICES_JP].includes(item?.title),
        'last-menus-dropdown': [MenuItems.RESOURCES_EN, MenuItems.RESOURCES_JP].includes(item?.title),
        'industry-menu-dropdown': [
          MenuItems.INDUSTRY_EN,
          MenuItems.INDUSTRY_JP,
          MenuItems.ABOUT_EN,
          MenuItems.INDUSTRY_JP,
        ].includes(item?.title),
      })}
      content={<MenuContent list={item.subMenu} type={item.popoverMenuType} onVisible={setVisible} />}
    >
      <Item
        key={Math.random()}
        className={classNames({
          'item-active': item.path === pathname,
          'item-last': isItemLast,
        })}
      >
        <Link href={item.path}>
          <a>{item.title}</a>
        </Link>
        <span className="icon-image">
          <Image className="drop-down-icon" width={8} height={6} src="/icons/down-arrow.svg" alt="drop-down-icon" />
        </span>
      </Item>
    </Popover>
  );
};
